
import Vue, { PropOptions } from "vue"; // @ts-ignore
import { getContentID } from "~/plugins/router";
import { Strapi } from "~/types/typings";
// @ts-ignore
import OgImage from "~/assets/img/OG-1200x630.png";

export default Vue.extend({
  head() {
    return {
      // @ts-ignore
      title: (this.Page?.Meta?.Title !== undefined)?this.Page?.Meta?.Title:this.Page?.Title?.replace(/\*\*/g, ""),
      //titleTemplate: "%s",
      meta: [
        {
          hid: "description",
          name: "description",
          // @ts-ignore
          content: (this.Page?.Meta?.Description !== null)?this.Page.Meta?.Description:this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:url",
          property: "og:url",
          content: "https://www.buro26.digital",
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          hid: "og:title",
          property: "og:title",
          // @ts-ignore
          content: (this.Page?.Meta?.Title !== undefined)?this.Page.Meta?.Title:this.Page?.Title?.replace(/\*\*/g, ""),
        },
        {
          hid: "og:description",
          property: "og:description",
            // @ts-ignore
            content: (this.Page?.Meta?.Description !== null)?this.Page.Meta?.Description:this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:image",
          property: "og:image",
          content: OgImage,
        }
      ],
      link: [
        {
          rel: "canonical",
          href:
            "https://www.buro26.digital" + (this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/'),
        },
      ],
    };
  },

  props: {
    $strapi: {
      type: Object,
      required: false,
    } as PropOptions<Strapi>,
  },
  components: {
    ZoneComponent: () => import("~/components/ZoneComponent.vue"),
  },
  async asyncData({
    error,
    route,
    $strapi,
    $config,
  }: {
    error: any;
    route: any;
    $strapi: Strapi;
    $config: any;
  }): Promise<any> {
    try {

      const slug = await getContentID(route);

      const response = await $strapi.graphql({
        query:
          `
          fragment ZoneComponents on PageContentDynamicZone {
            __typename
            ... on ComponentModuleContent {
              id
              Text
              Layout
              Image {
                data {
                  attributes {
                    url
                  }
                }
              }
              Style {
                Class
                ItemClass
              }
            }
            ... on ComponentModuleBlock {
              id
              Text
              blockImage: Image {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              Button {
                Title
                Url
                External
              }
              Smiley {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentModuleCollectie {
              id
              Title
              Description
              CollectionType
              Limiet
              Sorting
              Direction
              Filtering
              Special
              Url {
                Title
                Url
                External
              }
              Style {
                Class
                ItemClass
              }
              AlternativeLayout
            }
            ...on ComponentModuleVisual {
              id
              Media {
                data {
                  attributes {
                    url
                  }
                }
              }
              Title
              Style {
                Class
                ItemClass
              }
            }
            ...on ComponentModuleVisualList {
              id
              MediaList:Media {
                data {
                  attributes {
                    url
                  }
                }
              }
              Title
              Style {
                Class
                ItemClass
              }
            }
          }
          query{
              pages (filters: { Slug: { eq: "` + slug + `"} } ) {
                data {
                  id
                  attributes {
                    Title
                    Slug
                    ContentAbove {
                      ...ZoneComponents
                    }
                    Content {
                      ...ZoneComponents
                    }
                    ContentLeft {
                      ...ZoneComponents
                    }
                    ContentRight {
                      ...ZoneComponents
                    }
                    ContentBelow {
                      ...ZoneComponents
                    },
                    Meta {
                      Title,
                      Description
                    }
                  }
                }
              }
            }
          `,
      });

      const Page = await response?.pages?.data[0]?.attributes;
      const introText = Page.ContentAbove[0].Text;
      const metaDescription = introText
        .substring(Page.ContentAbove[0].Text.indexOf("\n") + 1)
        .trim();

      if (typeof Page == "undefined") {
        throw { statusCode: 404, message: "Page not found" };
      } else {
        const PageContentBlocks = [
          "Content",
          "ContentAbove",
          "ContentBelow",
          "ContentLeft",
          "ContentRight",
        ];
        PageContentBlocks.map((ContentBlock: any) => {
          if (Page[ContentBlock] && Page[ContentBlock].length > 0) {
            Page[ContentBlock].map((ContentItem: any) => {
              if (ContentItem.Text) {
                //replace img link by cdn
                ContentItem.Text = ContentItem.Text.replace(
                  /\/uploads\//g,
                  $config.cdnURL + "/uploads/"
                );

                //external link, add target=_blank
                const links = ContentItem.Text.match(/ \[([^\[]+)\](\(http.*\))/gm);
                if (links !== null && links.length > 0) {
                  links.map((link: String) => {
                    let href = link.match(/\((.*?)\)/);
                    let title = link.match(/\[(.*?)\]/);
                    if (href !== null && title !== null) {
                      ContentItem.Text = ContentItem.Text.replace(
                        link,
                        ' <a href="' + href[1] + '" target="_blank">' + title[1] + "</a>"
                      );
                    }
                  });
                }
              }
            });
          }
        });
        return { Page, metaDescription };
      }
    } catch (e) {
      return error({ statusCode: 404, message: "err message:" + e });
    }
  },
});
